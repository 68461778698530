:root {
  --blue-color: #071741;
  --green-color: #1EB66D;
  --black-hadd: #1F1F1F; 
  --black-para: #424242;  
}   
h1, h2, h3, h4, h5, h6{
  color: var(--black-hadd)!important;
}  
p{color: var(--black-para)!important; font-weight:regular!important;}

.modal-backdrop.fade {
  opacity: 9!important;
}
.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #fff!important;
  --bs-backdrop-opacity: 1!important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.getStartedabs{ position: relative;}
.getStarted{ text-align: center; padding: 30px; border: solid #dbdbdb 1px; top:0px; position: sticky; }
 .customSpace{ margin:3% 0 0 0 ;}
 jsf-form .jsf-form-title-main {
  font-size: 22px!important;
  font-weight: bold!important;
}
.secure{font-weight:bold!important;}
.costomerg{margin:15px 0;}
.textBlue{color: var(--blue-color);}
.textGreen{color: var(--green-color)!important;}
.textHadd{color: var(--black-hadd);}
.textPara{color: var(--black-para);}

.navbar-toggler {
  padding:0px!important;
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: 1px solid transparent!important;
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

.darkvanBg{background-color:var(--blue-color); color:#fff;}

.bnrBg{ 
  padding:7% 0; 
  background:#011224 url(../src/assets/img/bnrbg.jpg) no-repeat center top; 
  border-bottom:solid 8px var(--green-color);    
 
}
.aboutStep p{color: #424242; font-size:1.5rem;}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0px!important;
}

.stepBg{border:solid 1px #dbdbdb; border-radius:15px; padding: 12px; margin:12px 0;}
.stepBg:hover{border:solid 1px var(--green-color);  background-color: #f7faf9;}
.stepBg:hover .textHadd{color:var(--green-color)!important;}

.costMarg{padding:4% 0; border-top:solid #dbdbdb 1px ;}
.costMargabt{padding:4% 0; }

.lending p{font-size: 1.2rem!important; font-weight:regular!important;}

.accordion-button {
  position: relative;
  display: flex;outline: transparent!important;
  align-items: center;
  width: 100%;
  padding: 40px 30px !important;
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: #fff!important;
  border: 0!important;
  border-radius: 0!important;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border:2px solid #EFF2F6!important;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0px 0rem rgba(13, 110, 253, 0.25)!important;
}

.footerBg{background:  var(--blue-color); padding:4% 0 0 0;}

.discloser h4{color: #fff!important;}
.discloser p{color: #fff!important;}

.request{background:#18264A; padding:25px; border-radius: 15px; margin-top: 30px; }

.ftrAbout{ margin:30px 0;}
.ftrAbout p{color: #919BB7!important; margin:15px 0 0 0;}

.ftLinks{ margin: 0px; padding: 0;}
.ftLinks li{ list-style: none; margin:12px 0; padding: 0;}
.ftLinks li a{color: #919BB7!important; text-decoration: none;}
.ftLinks li a:hover{color: var(--green-color)!important;}

.copyRights{border-top:solid 1px #919BB7!important; padding:20px 0; text-align: center;;}
.copyRights p{color: #919BB7!important;}
 
.btn-group, .btn-group-vertical {
  position: relative;
  display: grid!important;
  vertical-align: middle;
}

.prograssBg{background-color:#dcffee; padding:12px 0; z-index: 9999!important;}
.costProg{ margin:12px 0;}
.progress, .progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #B0E9CE!important;
  --bs-progress-border-radius:12px!important;
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #B0E9CE!important;
  --bs-progress-bar-bg: #1EB66D!important;
  --bs-progress-bar-transition: width 0.6s ease;
  display: inline-flex!important;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius:12px!important;
  width: 80%!important;
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  color:#1EB66D!important;
  background-color:var(--blue-color)!important;
  border-color:#1EB66D!important;
}

.btn-primary { font-size: 1.2rem!important; text-align: left!important;
 background: #fff!important; font-weight:500!important;
 color:#696969!important; padding:30px 20px!important;
 border-radius:0px!important; margin:3px 0!important;
 border: solid #c7c7c7  1px!important;
}


.btnBrdr{ border-radius: 0!important;border:solid 2px var(--blue-color)!important; color: var(--blue-color)!important; font-size: 16px!important;}
.btnBrdr:hover{background: var(--blue-color)!important;color:#fff!important; }

.btnBrdrclr{ border-radius: 0!important;border:solid 2px var(--green-color)!important; color: var(--green-color)!important; font-size: 16px!important;}
.btnBrdrclr:hover{background: var(--green-color)!important;color:#fff!important; }


.btnFill{border:solid 2px var(--green-color)!important; padding:10px 10px!important; background-color:var(--green-color)!important; 
  color: #fff!important;  border-radius: 0!important; font-size: 1.3rem!important; 
  font-weight: semibold!important;}
.btnFill:hover{background: var(--blue-color)!important;color:#fff!important; }

.btnWidth{min-width:145px; padding:12px 0!important;}
.form-select-lg {
  padding: 1rem!important; 
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
option {
  font-weight: normal;
  display: block;
  min-height: 1.6em!important;
  padding:12px 1px!important;
  white-space: nowrap;
}

.form-check-input:checked {
  background-color:var(--green-color)!important;
  border-color:var(--green-color)!important;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width:1.4em!important;
  height:1.4em!important;
  margin-top: .25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

label { margin:5px 0 0 6px!important;
  display: inline-block;
  font-size: 18px!important;
}
 
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%!important;
}

.npHero{background: url(../src/assets/img/npherobg.jpg) center top; padding:7% 0;}

.bgCostoGreen{background:#ECFFF6; padding:3% 0;}

.formColor{background-color: #F4F8FC!important; border:transparent!important; font-size:1rem!important; padding:22px 15px!important;}

.policy{}
.policy h4{ font-size:24px; font-weight:bold; line-height:normal}
.policy p{ font-size:18px; font-weight:normal; line-height: 1.5 !important;}

.slick-track {
  width: 100%!important;
}

.slick-slide {
  float: left;
  min-height: 1px;
}